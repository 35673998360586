import {Button, Chip, Code, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, Tab, Tabs, useDisclosure} from "@nextui-org/react";
import {useState} from "react";

interface DetailData {
    name: string,
    state: string,
    brief: string,
    description: string,
    images: string[],
    attributes: {
        name: string,
        values: string[],
    }[]
}

export default function Detail(props: { item: DetailData, squareImage?: boolean }) {
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const [touchStart, setTouchStart] = useState(0);

    return (
        <>
            <div className={"grid overflow-hidden"} style={{gridTemplateRows: "1fr auto"}} onClick={onOpen}
                 onTouchStart={(e) => {
                     setTouchStart(e.touches[0].clientY);
                 }}
                 onTouchEnd={(e) => {
                     const distance = e.changedTouches[0].clientY - touchStart;
                     if ((distance >= 0 && distance < 25) || (distance < 0 && distance > -25)) {
                         onOpen();
                     }
                 }}>
                <div style={{backgroundImage: `url(${props.item.images[0]})`, width: 320, height: props.squareImage ? 320 : 480}}
                     className={"bg-cover bg-no-repeat bg-center"} />
                <div className={`${props.item.state !== "normal" ? "grid gap-3" : ""} py-1`} style={{gridTemplateColumns: "1fr 1fr"}}>
                    <h1 className={`font-mono text-lg ${props.item.state !== "normal" ? "text-right" : "text-center"}`}>{props.item.name}</h1>
                    {props.item.state === "offshore" && <Chip className={"h-full"} size={"sm"} color={"warning"}>Offshore</Chip>}
                    {props.item.state === "out_of_stock" && <Chip className={"h-full"} size={"sm"} color={"default"}>Out Of Stock</Chip>}
                </div>
            </div>
            <Modal size={"5xl"} backdrop={"blur"} isOpen={isOpen} onOpenChange={onOpenChange} scrollBehavior={"inside"}>
                <ModalContent className={"h-full overflow-y-auto"}>
                    {(onClose) => (
                        <>
                            <ModalHeader>
                                <h1 className={"text-xl font-mono"}>Product Detail</h1>
                            </ModalHeader>
                            <ModalBody>
                                <div className={"grid"} style={{gridTemplateRows: "1fr auto"}}>
                                    <div className={"grid grid-cols-1 md:grid-cols-2 w-full gap-x-5"}>
                                        <div className={"relative overflow-x-hidden rounded-2xl"} style={{minHeight: 400}}>
                                            <div style={{backgroundImage: `url(${props.item.images[0]})`}}
                                                 className={"bg-contain w-full h-full bg-no-repeat bg-center"} />
                                        </div>
                                        <div className={"grid gap-y-5 w-full"} style={{gridTemplateRows: "auto auto 1fr auto"}}>
                                            <h1 className={"font-bold text-5xl"}>{props.item.name}</h1>
                                            <p className={"text-medium text-gray-500"}>{props.item.brief}</p>
                                            <div className={"grid grid-cols-1 gap-5"}>
                                                {props.item.attributes.map((attr, index) => {
                                                    return (
                                                        <div key={index} className={"grid gap-3"} style={{gridTemplateColumns: "auto 1fr"}}>
                                                            <h1 className={"font-bold"}>{attr.name}:</h1>
                                                            <div
                                                                className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-1"}>
                                                                {attr.values.map((val, index) => (
                                                                    <Code key={index} size={"sm"}
                                                                          className={"h-full whitespace-pre-wrap px-5 text-center"}>
                                                                        {val}
                                                                    </Code>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    </div>
                                    <Tabs variant={"underlined"}>
                                        <Tab key={"description"} title={"Description"} className={"overflow-y-auto p-0"}>
                                            <div className={"p-5 mt-3"} style={{minHeight: "10rem", backgroundColor: "#F0F5F0"}}
                                                 dangerouslySetInnerHTML={{__html: props.item.description}} />
                                        </Tab>
                                    </Tabs>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="danger" variant="light" onPress={onClose}>Close</Button>
                                <Button color="primary" onPress={() => {
                                    window.open("/contact", "_blank");
                                }}>Contact</Button>
                            </ModalFooter>
                        </>
                    )}
                </ModalContent>
            </Modal>
        </>
    );
}
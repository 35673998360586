import {CSSProperties, ReactNode, useState} from "react";
import {Link, Navbar, NavbarBrand, NavbarContent, NavbarItem, NavbarMenu, NavbarMenuItem, NavbarMenuToggle} from "@nextui-org/react";
import {useLocation} from "react-router-dom";
import {IconPhone} from "@tabler/icons-react";
import Logo from "./Logo";

export default function FullscreenBase(props: {
    children: ReactNode | ReactNode[],
    backgroundImage?: string,
    contentStyle?: CSSProperties,
    className?: string,
    style?: CSSProperties,
}) {
    const menuItems: { Name: string, Path: string } [] = [
        {
            Name: "Home",
            Path: "/#intro",
        },
        {
            Name: "Product",
            Path: "/#product_nav",
        },
        {
            Name: "About",
            Path: "/#about",
        },
        {
            Name: "Contact",
            Path: "/#contact",
        },
    ];
    const path = useLocation().pathname;
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <div className={`fixed w-full h-full overflow-y-auto ${props.className || ""}`} style={props.style}>
            <Navbar shouldHideOnScroll isBordered isMenuOpen={menuOpen} onMenuOpenChange={setMenuOpen}>
                <NavbarContent>
                    <NavbarMenuToggle className="sm:hidden" />
                    <NavbarBrand>
                        <Logo height={"36"} />
                        <p className="font-mono font-extrabold text-xl sm:text-2xl md:text-3xl">Zelitt</p>
                    </NavbarBrand>
                </NavbarContent>
                <NavbarContent className="hidden sm:flex gap-8" justify="center">
                    {menuItems.map((item, index) => {
                        return (
                            <NavbarItem isActive={path === item.Path || (path === "/detail" && item.Path === "/product")}
                                        key={item.Name + index.toString()}>
                                <Link href={item.Path}
                                      className={`text-lg ${(path === item.Path || (path === "/detail" && item.Path === "/product")) ? "text-foreground" : "text-foreground-500"}`}>
                                    {item.Name}
                                </Link>
                            </NavbarItem>
                        );
                    })}
                    <div className={"flex items-center gap-1"}>
                        <IconPhone />
                        <h1>+86 13338362979</h1>
                    </div>
                </NavbarContent>
                <NavbarMenu className={"gap-y-3"} style={{backgroundColor: "rgba(0, 0, 0, 0.5)"}}>
                    {menuItems.map((item, index) => (
                        <NavbarMenuItem isActive={path === item.Path} key={index}>
                            <Link href={item.Path} onClick={() => setMenuOpen(false)}
                                  className={`text-3xl ${(path === item.Path || (path === "/detail" && item.Path === "/product")) ? "text-white" : "text-foreground-300"}`}
                                  size="lg">
                                {item.Name}
                            </Link>
                        </NavbarMenuItem>
                    ))}
                    <NavbarMenuItem className={"flex items-center gap-1 text-xl text-white"}>
                        <IconPhone />
                        <h1>+86 13338362979</h1>
                    </NavbarMenuItem>
                </NavbarMenu>
            </Navbar>
            {props.children}
        </div>
    );
}

import {IconBasketDollar, IconBrandNytimes, IconHeadset, IconShieldCheck, IconUsersGroup} from "@tabler/icons-react";

export default function About() {
    const partner_bg = require("../assets/img/partner.jpeg");
    const statics = [
        {
            stats: "300+",
            description: "Success trades"
        },
        {
            stats: "15+",
            description: "Partnership Companies"
        },
        {
            stats: "7000+",
            description: "Customers"
        },
        {
            stats: "$1,700,000+",
            description: "Money Earned"
        },
    ];
    const concepts = [
        {
            logo: <IconShieldCheck className={"text-red-600 size-8 sm:size-12 md:size-16"} />,
            title: "Trusted Quality",
            content: "Committed to delivering outstanding product quality to ensure customer satisfaction",
        },
        {
            logo: <IconHeadset className={"text-red-600 size-8 sm:size-12 md:size-16"} />,
            title: "Premium Service",
            content: "Provides professional and attentive customer service to ensure that customer needs are met",
        },
        {
            logo: <IconBasketDollar className={"text-red-600 size-8 sm:size-12 md:size-16"} />,
            title: "Competitive Pricing",
            content: "Offer competitively priced products that provide excellent value for money to our customers",
        },
        {
            logo: <IconBrandNytimes className={"text-red-600 size-8 sm:size-12 md:size-16"} />,
            title: "Active Innovating",
            content: "Continuously innovate, striving to introduce new products and solutions that meet market demands",
        },
    ];

    return (
        <div id={"about"} className={"w-full px-10 pt-20 pb-10"} style={{minHeight: "100%"}}>
            <div className={"grid grid-cols-1 md:grid-cols-2 gap-10"}>
                <div className={"flex items-center"}>
                    <div className={"grid"} style={{gridTemplateRows: "auto auto 1fr"}}>
                        <IconUsersGroup className={"text-red-600 size-8 sm:size-12 md:size-16"} />
                        <h1 className={"font-extrabold text-2xl sm:text-3xl md:text-4xl"}>Who Are We</h1>
                        <p className={"text-medium sm:text-lg md:text-xl text-foreground-600 pt-3"}>
                            Xiamen Zelitt Trading Co., Ltd. is a professional enterprise
                            specializing in the production of outdoor goods, located in Xiamen City, Fujian Province. Our products
                            include outdoor apparel, camping supplies, and various equipment. Designed with rationality,
                            flexibility,
                            durability, and portability, our products are exported to many countries and regions, including the
                            United
                            States, Canada, Europe, Australia, and Southeast Asia. Our products enjoy a good reputation among
                            customers.
                            We welcome customers, partners, and friends from all over the world to contact us for cooperation and
                            mutual
                            benefit.
                        </p>
                    </div>
                </div>
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                    {concepts.map((item, index) => {
                        return (
                            <div key={index} className={"grid p-5 bg-gray-100"} style={{gridTemplateRows: "auto auto 1fr"}}>
                                {item.logo}
                                <h1 className={"font-extrabold text-xl sm:text-2xl md:text-3xl"}>{item.title}</h1>
                                <p className={"text-medium sm:text-lg md:text-xl text-foreground-600 pt-3"}>
                                    {item.content}
                                </p>
                            </div>
                        );
                    })}
                </div>
            </div>
            <div className={"flex flex-col items-center text-white mt-10"}
                 style={{backgroundImage: `url(${partner_bg})`, backgroundSize: "100% 100%"}}>
                <div className={"w-full h-full py-3 px-10 text-center"} style={{backgroundColor: "rgba(0,0,0,0.7)"}}>
                    <h1 className={"font-extrabold text-xl sm:text-2xl md:text-3xl pt-3 pb-10"}>Trusted by many companies</h1>
                    <div className={"grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-5"}>
                        {statics.map((item, index) => {
                            return (
                                <div key={index} className={"grid gap-1"} style={{gridTemplateRows: "1fr auto"}}>
                                    <h1 className={"text-3xl font-bold"}>{item.stats}</h1>
                                    <h1 className={"text-xl font-mono"}>{item.description}</h1>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
}
import FullscreenBase from "../components/FullscreenBase";
import React from "react";
import About from "./About";
import Contact from "./Contact";
import ProductNav from "./ProductNav";

export default function Home() {
    return (
        <FullscreenBase className={"sm:scroll-smooth"}>
            <Intro />
            <ProductNav />
            <About />
            <Contact />
        </FullscreenBase>
    );
}

function Intro() {
    const image = require("../assets/img/intro.jpeg");

    return (
        <div id={"intro"} className={"w-full h-full"} style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
        }}>
            <div className={"w-full h-full flex flex-col items-center justify-center"} style={{backgroundColor: "rgba(0,0,0,0.5)"}}>
                <p className={"text-white text-5xl font-bold max-w-[90%]"}>Quality Outdoor Equipment Supplier</p>
                <p className={"text-white text-2xl max-w-[90%]"}>Explore nature, starting with our outdoor gear</p>
            </div>
        </div>
    );
}
import {Card, Image, Link} from "@nextui-org/react";
import {useState} from "react";

export default function ProductNav() {
    return (
        <div id={"product_nav"} className={"w-full h-full flex flex-col items-center justify-center"}>
            <p className={"text-5xl"}>What We Provide</p>
            <div className={"mt-10 grid gap-10 grid-cols-1 sm:grid-cols-2"}>
                <div className={"flex items-center sm:justify-end justify-center"}>
                    <EquipmentTab />
                </div>
                <div className={"flex items-center justify-center sm:justify-start"}>
                    <SportswearTab />
                </div>
            </div>
        </div>
    );
}

function EquipmentTab() {
    const img = require(`../assets/img/tent.jpeg`);
    const [hover, setHover] = useState(false);

    return (
        <Link href={"/product?category=1"} style={{width: "60%"}}>
            <Card className={"bg-white text-black"}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
            >
                <Image isZoomed src={img}></Image>
                <div
                    className={`z-10 absolute rounded py-3 px-5 whitespace-nowrap ${hover ? "text-white bg-black" : "text-black bg-white"}`}
                    style={{top: "50%", left: "50%", transform: "translateY(-50%) translateX(-50%)"}}>
                    <p>Camping Equipment</p>
                </div>
            </Card>
        </Link>
    );
}

function SportswearTab() {
    const img = require(`../assets/img/sportswear.jpeg`);
    const [hover, setHover] = useState(false);

    return (
        <Link href={"/product?category=2"} style={{width: "60%"}}>
            <Card className={"bg-white text-black"}
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
            >
                <Image isZoomed src={img}></Image>
                <div
                    className={`z-10 absolute rounded py-3 px-5 whitespace-nowrap ${hover ? "text-white bg-black" : "text-black bg-white"}`}
                    style={{top: "50%", left: "50%", transform: "translateY(-50%) translateX(-50%)"}}>
                    <p>Sportswear</p>
                </div>
            </Card>
        </Link>
    );
}
export default function Logo(props: { height?: string, color?: string }) {
	return <svg version="1.1" id="图层_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 174.97 182.71"
	            height={props.height} style={{fill: props.color}}>
		<style>
			{`enable-background: new 0 0 174.97 182.71; fill=red`}
		</style>
		<polygon points="32.65,38.32 32.65,56.52 96.26,56.52 32.71,124.98 32.71,149.56 39.05,149.56 138.71,42.48 138.71,38.32 " />
		<path d="M138.71,149.56H61.03c9.78-10.76,19.57-21.52,29.35-32.27c10.42,0,20.84,0,31.26,0c-4.46,4.95-8.92,9.89-13.39,14.84h30.45
	V149.56z" />
	</svg>;
}
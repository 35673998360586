import FullscreenBase from "../components/FullscreenBase";
import {Code, Divider, Pagination} from "@nextui-org/react";
import React, {useEffect, useState} from "react";
import {DoRequest} from "../utils";
import Detail from "../components/Detail";
import {useLocation} from "react-router-dom";

interface ItemData {
    name: string,
    state: string,
    brief: string,
    description: string,
    images: string[],
    attributes: {
        name: string,
        values: string[],
    }[]
}

export default function Product() {
    const location = useLocation();
    const category = parseInt(new URLSearchParams(location.search).get("category") as string);
    const [getProductError, setGetProductError] = useState<string | null>(null);
    const [products, setProducts] = useState<ItemData[] | null>(null);
    const [page, setPage] = useState(1);
    const [maxPage, setMaxPage] = useState(-1);

    const getProduct = () => {
        DoRequest("get", `/get_product?page=${page}&options=${JSON.stringify({1: category})}`)
            .then(res => {
                if (res.error) {
                    setGetProductError(`failed to get products from server, please try again later.\nYou can contact us with this error message: [${Math.floor(Date.now() / 1000)}][${res.error}]`);
                    setProducts(null);
                    setMaxPage(-1);
                } else {
                    setGetProductError(null);
                    res.data && setMaxPage(res.data["max_page"]);
                    res.data && setProducts(res.data["products"]);
                }
            });
    };

    useEffect(() => {
        getProduct();
    }, [page]);

    return (
        <FullscreenBase>
            <div className={"w-full h-full"}>
                <p className={"text-5xl text-start w-full px-5 pt-8"}>
                    {category === 1 ? "Camping Equipment" : "Sportswear"}
                </p>
                <Divider className={"my-3"} />
                <div className={"flex flex-row items-center justify-center flex-wrap px-5"}>
                    {products === null &&
						<Code size={"lg"} color={getProductError ? "danger" : "secondary"}
						      className={"whitespace-pre-wrap px-5 text-center mt-10"}>
                            {getProductError ? getProductError : "Loading Items"}
						</Code>
                    }
                    {products && products.length === 0 &&
						<Code size={"lg"} color={"warning"} className={"whitespace-pre-wrap px-5 text-center mt-10"}>
							No Item Found
						</Code>
                    }
                    {products && products.length !== 0 &&
						<div className={"grid h-full grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-4 p-5"}>
                            {products.map((item, index) => (
                                <Detail item={item} key={index} squareImage={category === 1} />
                            ))}
						</div>
                    }
                    {maxPage > 0 && products && products.length !== 0 &&
						<div className={"grid"} style={{gridTemplateColumns: "1fr auto 1fr"}}>
							<div />
							<Pagination className={"m-0"} total={maxPage} initialPage={1} variant={"flat"}
							            showControls={maxPage > 1}
							            isCompact
							            onChange={setPage} page={page} />
							<div />
						</div>
                    }
                </div>
            </div>
        </FullscreenBase>
    );
}
import axios, {AxiosError} from "axios";

export async function DoRequest(method: "get" | "post", url: string, data: any = undefined): Promise<{ data: any, error: string | null }> {
	const reqFunc = method === "get" ? axios.get : axios.post;
	return reqFunc(url, data)
		.then(res => {
			return res.data["code"] === 0 ?
				{
					data: res.data["data"],
					error: null
				} :
				{
					data: null,
					error: res.data["err"]
				};
		})
		.catch((err: AxiosError) => {
			return {
				data: null,
				error: err.message
			};
		});
}

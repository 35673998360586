import React, {ReactNode, useState} from "react";
import {
    IconBulb,
    IconClock,
    IconMail,
    IconMapPin,
    IconPhone,
    IconSquareRoundedCheck,
    IconSquareRoundedX,
    IconUser
} from "@tabler/icons-react";
import {Button, Card, CardBody, CardHeader, Code, Input, Textarea} from "@nextui-org/react";
import {DoRequest} from "../utils";

export default function Contact() {
    const contactMethods: { icon: ReactNode, title: string, content: string }[] = [
        {
            icon: <IconMapPin stroke={2} className={"size-20"} />,
            title: "Address",
            content: "203, No. 1 Taiwan Street, Huli District, Xiamen City, Fujian Province, China."
        },
        {
            icon: <IconPhone stroke={2} className={"size-20"} />,
            title: "Call us",
            content: "+86 13338362979 <br> +86 18030296883"
        },
        {
            icon: <IconMail stroke={2} className={"size-20"} />,
            title: "Email",
            content: "info@zelitt.com"
        },
        {
            icon: <IconClock stroke={2} className={"size-20"} />,
            title: "Open Hour",
            content: "08:00~ 20:00 UTC+8"
        },
    ];
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [subject, setSubject] = useState("");
    const [description, setDescription] = useState("");
    const [httpResponse, setHttpResponse] = useState<string | null>(null);

    const sendContactMessage = () => {
        DoRequest("post", "/send_contact_message", {
            name: name,
            email: email,
            subject: subject,
            description: description
        })
            .then(res => {
                res.error && setHttpResponse(`failed to send contact message, please try again later.\nYou can contact us with this error message: [${Math.floor(Date.now() / 1000)}][${res.error}]`);
                res.data && setHttpResponse("OK");
            });
    };

    return (
        <div id={"contact"} className={"px-10 pt-20 pb-10 w-full flex flex-col justify-center items-center"} style={{minHeight: "100%"}}>
            <div className={"text-center pb-10"}>
                <div className={"w-full grid gap-y-1"} style={{gridTemplateRows: "auto auto"}}>
                    <h1 className={"text-3xl sm:text-4xl md:text-5xl font-extrabold"}>Contact Us</h1>
                    <p className={"text-foreground-600 font-bold"}>We are glad to help you</p>
                </div>
            </div>
            <div className={"grid grid-cols-1 md:grid-cols-2 gap-5"}>
                <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                    {contactMethods.map((item, index) => (
                        <Card key={index} className={"p-3 rounded-none border-0"}>
                            <CardHeader className={"text-red-500"}>
                                {item.icon}
                            </CardHeader>
                            <CardBody>
                                <h1 className={"text-xl md:text-2xl font-bold"}>{item.title}</h1>
                                <p className={"text-lg md:text-xl font-mono pt-1"} dangerouslySetInnerHTML={{__html: item.content}} />
                            </CardBody>
                        </Card>
                    ))}
                </div>
                <Card className={"p-5 rounded-none"}>
                    <form className={"grid gap-y-3"} style={{gridTemplateRows: "repeat(6,min-content)"}}>
                        <h1 className={"text-center text-2xl md:text-3xl font-bold pb-5"}>Or send us a message</h1>
                        {httpResponse &&
							<Code color={httpResponse === "OK" ? "success" : "danger"}
							      className={"whitespace-pre-wrap px-5 text-center"}>
                                {httpResponse === "OK" ? <IconSquareRoundedCheck /> : <IconSquareRoundedX />} {httpResponse}
							</Code>
                        }
                        <div className={"grid grid-cols-1 md:grid-cols-2 gap-3"}>
                            <Input isRequired size={"lg"} type={"text"} label={"Name"} startContent={<IconUser />} value={name}
                                   onValueChange={(name: string) => {
                                       setName(name);
                                       setHttpResponse(null);
                                   }} />
                            <Input isRequired size={"lg"} type={"email"} label={"Email"} startContent={<IconMail />} value={email}
                                   onValueChange={(email: string) => {
                                       setEmail(email);
                                       setHttpResponse(null);
                                   }} />
                        </div>
                        <Input isRequired size={"lg"} type={"text"} label={"Subject"} startContent={<IconBulb />} value={subject}
                               onValueChange={(subject: string) => {
                                   setSubject(subject);
                                   setHttpResponse(null);
                               }} />
                        <Textarea minRows={8} maxRows={12} isRequired label="Description" labelPlacement="inside"
                                  placeholder="Enter your description" size={"lg"} value={description}
                                  onValueChange={(description: string) => {
                                      setDescription(description);
                                      setHttpResponse(null);
                                  }} />
                        <Button color={"danger"} onClick={sendContactMessage}>Submit</Button>
                    </form>
                </Card>
            </div>
        </div>
    );
}
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import {createBrowserRouter, redirect, RouterProvider} from "react-router-dom";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";
import Product from "./pages/Product";
import {NextUIProvider} from "@nextui-org/react";
import axios from "axios";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/index",
        children: [
            {
                index: true,
                loader: async () => redirect("/")
            }
        ]
    },
    {
        path: "/home",
        children: [
            {
                index: true,
                loader: async () => redirect("/")
            }
        ]
    },
    {
        path: "/product",
        element: <Product />
    },
    {
        path: "/about",
        element: <About />
    },
    {
        path: "/contact",
        element: <Contact />
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

axios.defaults.baseURL = "/api";

// worker.start().then(() => {
//     root.render(
//         <React.StrictMode>
//             <NextUIProvider>
//                 <RouterProvider router={router} />
//             </NextUIProvider>
//         </React.StrictMode>
//     );
// });


root.render(
    <React.StrictMode>
        <NextUIProvider>
            <RouterProvider router={router} />
        </NextUIProvider>
    </React.StrictMode>
);
